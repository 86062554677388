import httpClient from '../httpClient/httpClient';

const FeaturesService = {
  getFeatureNames() {
    return httpClient.get('api/get-feature-names', {});
  },
  getFeature(featureID) {
    return httpClient.get('api/get-feature', { params: { featureID } });
  },
};

export default FeaturesService;
