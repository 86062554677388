<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Feature License Settings" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align-self="center" cols="5">
        <v-autocomplete
          v-model="selectedOrganization"
          :items="organizationNames"
          color="primaryAccent"
          dense
          hide-details
          label="Organization"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col align-self="center" cols="5">
        <v-autocomplete
          v-model="selectedClinic"
          :filled="!selectedOrganization"
          :items="clinicNames"
          :readonly="!selectedOrganization"
          color="primaryAccent"
          dense
          hide-details
          label="Clinic"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align-self="center" cols="5">
        <v-autocomplete
          v-model="selectedLocation"
          :filled="!selectedClinic"
          :items="locationNames"
          :readonly="!selectedClinic"
          color="primaryAccent"
          dense
          hide-details
          label="Location"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col align-self="center" cols="auto">
        <mex-btn :disabled="!selectedLocation" icon="mdi-refresh" iconOnly @click="fetchLocationFeatureSettings" />
      </v-col>
    </v-row>
    <mex-sperm-spinner v-if="locationFeatureSettingsViewLoading" :spinnerText="locationFeatureSettingsViewLoadingText" />
    <template v-else-if="locationFeatureSettings">
      <v-row justify="center">
        <v-col cols="12">
          <mex-data-table
            :data="locationFeatureSettings"
            :headers="getTableHeaders"
            tableClass="foreground"
            :footer-props="{
              'items-per-page-options': getTablePagination.rowsPerPage,
            }"
            :items-per-page="getTablePagination.defaultRowsPerPage"
          >
            <template v-slot:item.activationDate="{ item }">
              {{ $dateFormatter.convertJsonDate(item.activationDate).full }}
            </template>
            <template v-slot:item.deactivationDate="{ item }">
              {{ $dateFormatter.convertJsonDate(item.deactivationDate).full }}
            </template>
            <template v-slot:item.featureReferenceData="{ item }">
              <mex-btn
                v-if="formatReferenceData(item.featureReferenceData)"
                icon="mdi-arrow-expand-all"
                iconOnly
                @click="openReferenceDataOverlay(formatReferenceData(item.featureReferenceData))"
              />
            </template>
            <template v-slot:item.actions="{ item }">
              <mex-btn icon="mdi-pencil" iconOnly @click="editLocationFeatureSetting(item)" />
              <mex-btn icon="mdi-delete" iconOnly @click="confirmDelete(item)" />
            </template>
          </mex-data-table>
        </v-col>
      </v-row>
      <v-row v-if="editMode" justify="center">
        <v-col cols="auto">
          <mex-btn icon="mdi-plus" content="Add feature settings" @click="showAddFeatureLicenseDialog = true" />
        </v-col>
        <v-col cols="auto">
          <mex-btn icon="mdi-flash" iconOnly @click="getLastReportedFeatures" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <mex-btn v-if="!editMode && writeFeatureSettings" content="Edit" @click="activateEditMode" />
        </v-col>
        <v-col cols="auto">
          <mex-btn v-if="editMode" content="Save changes" @click="saveLocationFeatureSettingsChanges" />
        </v-col>
        <v-col cols="auto">
          <mex-btn v-if="editMode" content="Cancel" @click="deactivateEditMode" />
        </v-col>
      </v-row>
    </template>
    <save-request :showSaveRequest="showSaveRequest" @closeSaveRequest="showSaveRequest = false" />
    <mex-dialog :showDialog="showAddFeatureLicenseDialog" dialogTitle="Add location feature setting" width="50%">
      <template v-slot:dialog-content>
        <v-row justify="center">
          <v-col cols="10">
            <v-autocomplete v-model="selectedFeature" :items="featureNames" color="primaryAccent" dense hide-details label="Feature" outlined>
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                  <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="11">
            <v-divider />
          </v-col>
        </v-row>
        <v-row v-if="featureData.featureID" justify="center">
          <v-col cols="3">
            <v-text-field v-model="featureData.keyfield" color="primaryAccent" dense hide-details label="Shortname" outlined />
          </v-col>
          <v-col cols="9">
            <v-text-field v-model="featureData.description" color="primaryAccent" dense hide-details label="Description" outlined />
          </v-col>
        </v-row>
        <v-row v-if="featureData.featureID">
          <v-col cols="6">
            <v-textarea
              id="textarea"
              v-model="featureData.newReferenceData"
              label="Reference Data"
              outlined
              rows="10"
              solo
              @keydown.tab.prevent="insertTab($event)"
            />
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="featureData.clinicWideUserLimit"
                  color="primaryAccent"
                  dense
                  hide-details
                  label="Clinic wide user limit"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="featureData.locationWideUserLimit"
                  color="primaryAccent"
                  dense
                  hide-details
                  label="Location wide user limit"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <v-menu
                  ref="activationDateMenu"
                  v-model="activationDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="featureData.activationDate"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="featureData.activationDate"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      hide-details
                      label="Activation date"
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="featureData.activationDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn @click="activationDateMenu = false"> Cancel</v-btn>
                    <v-btn @click="$refs.activationDateMenu.save(featureData.activationDate)"> SET THIS DATE</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <v-menu
                  ref="deactivationDateMenu"
                  v-model="deactivationDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="featureData.deactivationDate"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="featureData.deactivationDate"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      hide-details
                      label="Deactivation date"
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="featureData.deactivationDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn @click="deactivationDateMenu = false"> Cancel</v-btn>
                    <v-btn @click="$refs.deactivationDateMenu.save(featureData.deactivationDate)"> SET THIS DATE</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="auto">
                <v-checkbox v-model="addToAllLocations" color="red" label="Apply for all locations of clinic" />
              </v-col>
            </v-row>
            <v-row v-if="addToAllLocations" justify="center">
              <v-col cols="12">
                <mex-sheet color="warning" rounded>
                  <mex-p
                    alignment="center"
                    content="Keep in mind that this changes will be applied to every location this current location is a member of!"
                    fontSize="overline"
                  />
                </mex-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <mex-sperm-spinner v-if="selectedFeature && featureDataLoading" :spinnerText="featureDataLoadingText" />
      </template>
      <template v-slot:dialog-actions>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-btn content="Save" @click="saveFeatureSetting" />
          </v-col>
          <v-col cols="auto">
            <mex-btn content="Close" @click="resetFeatureData" />
          </v-col>
        </v-row>
      </template>
    </mex-dialog>
    <v-overlay v-if="showConfirmDelete">
      <mex-sheet color="warning" rounded>
        <v-row justify="center">
          <v-col cols="12">
            <mex-p content="Do you really want to delete this feature setting?" fontSize="h6" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-p content="Deleting this entry cannot be undone. " />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-btn content="delete" icon="mdi-delete" @click="deleteLocationFeatureSetting" />
          </v-col>
          <v-col cols="auto">
            <mex-btn content="Cancel" icon="mdi-close" @click="confirmDelete" />
          </v-col>
        </v-row>
      </mex-sheet>
    </v-overlay>
    <v-overlay v-if="showReferenceDataOverlay">
      <v-row>
        <mex-card cardClass="foreground" icon="mdi-magnify" title="Reference Data" width="100%">
          <v-row justify="end">
            <v-col cols="12">
              <mex-btn icon="mdi-close" iconOnly @click="showReferenceDataOverlay = false" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div style="min-width: 50vh; overflow: scroll">
                <v-textarea
                  id="textarea"
                  v-model="referenceDataOverlayContent"
                  class="text-no-wrap"
                  full-width
                  label="Reference Data"
                  no-resize
                  outlined
                  readonly
                  rows="10"
                  solo
                />
              </div>
            </v-col>
          </v-row>
        </mex-card>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SaveRequest from '../../components/LicSrvComponents/SaveRequest.vue';
import ClinicsService from '../../services/clinics.service';
import LocationsService from '../../services/locations.service';
import OrganizationsService from '../../services/organizations.service';
import LocationFeatureSettingsService from '../../services/locationFeatureSettings.service';
import FeatureService from '../../services/feature.service';
import requiredPermissions from '../../requiredPermissions';
import tablePagination from '../../config/tablePagination.config';
import LocationFeaturesService from "../../services/locationFeatures.service";
import keyListenerManager from '../../functions/keyListenerManager';
import {assignSeveralProperties} from '../../functions/assignPropertyIfExists';

export default {
  name: 'FeatureLicenseSettings',
  components: { SaveRequest },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getSelectedClinic() {
      return this.selectedClinic;
    },
    getTableHeaders() {
      if (!this.editMode || !this.getUserPermissions.includes('write_License Administration_Feature License')) {
        return this.locationFeatureSettingsTableHeaders.filter((x) => {
          return x.value !== 'actions';
        });
      }
      return this.locationFeatureSettingsTableHeaders;
    },
    writeFeatureSettings() {
      return this.getUserPermissions.includes('write_License Administration_Feature License');
    },
    getTablePagination() {
      return tablePagination;
    },
  },
  data() {
    return {
      // visualization
      editMode: false,
      showSaveRequest: false,
      locationFeatureSettingsViewLoading: false,
      showAddFeatureLicenseDialog: false,
      locationFeatureSettingsViewLoadingText: 'loading location feature settings',
      featureDataLoading: true,
      featureDataLoadingText: 'loading feature data',
      showReferenceDataOverlay: false,
      showConfirmDelete: false,
      // selection data
      referenceDataOverlayContent: null,
      newReferenceData: '',
      addToAllLocations: false,
      selectedOrganization: null,
      selectedClinic: null,
      selectedLocation: null,
      selectedFeature: null,
      organizationNames: [],
      clinicNames: [],
      locationNames: [],
      featureNames: [],
      preSelection: {
        clinic: null,
        organization: null,
      },
      featureData: {
        featureID: null,
        keyfield: null,
        description: null,
        clinicWideUserLimit: null,
        locationWideUserLimit: null,
        activationDate: null,
        deactivationDate: null,
        newReferenceData: null,
      },
      activationDate: null,
      activationDateMenu: false,
      deactivationDate: null,
      deactivationDateMenu: false,
      deleteFeatureSettingID: null,
      locationFeatureSettings: null,
      locationFeatureSettingsTableHeaders: [
        {
          text: 'Feature short name',
          align: 'start',
          sortable: true,
          value: 'Feature.keyfield',
        },
        {
          text: 'Feature name',
          align: 'start',
          sortable: true,
          value: 'Feature.name',
        },
        {
          text: 'Activation date',
          align: 'center',
          sortable: true,
          value: 'activationDate',
        },
        {
          text: 'Deactivation date',
          align: 'center',
          sortable: true,
          value: 'deactivationDate',
        },
        {
          text: 'Clinic wide user limit',
          align: 'center',
          sortable: true,
          value: 'clinicWideUserLimit',
        },
        {
          text: 'Location wide user limit',
          align: 'center',
          sortable: false,
          value: 'locationWideUserLimit',
        },
        {
          text: 'Reference data',
          align: 'center',
          sortable: false,
          value: 'featureReferenceData',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
        },
      ],
      hasPermission: false,
      // key listener
      boundEditModeAndSaveShortCutListener: null,
    };
  },
  watch: {
    selectedOrganization: {
      handler() {
        this.fetchClinicNames();
        this.$store.commit('selectedProperties/setFeatureLicenseSettingsOrganization', this.selectedOrganization);
      },
    },
    selectedClinic: {
      handler() {
        this.fetchLocationNames();
        this.$store.commit('selectedProperties/setFeatureLicenseSettingsClinic', this.selectedClinic);
      },
    },
    selectedLocation: {
      handler() {
        this.fetchLocationFeatureSettings();
        this.$store.commit('selectedProperties/setFeatureLicenseSettingsLocation', this.selectedLocation);
      },
    },
    selectedFeature: {
      handler() {
        this.fetchFeature();
      },
    },
  },
  created() {
    keyListenerManager.editModeAndSaveShortCut(
      this,
      document,
      'editModeAndSaveShortCutListener',
      'boundEditModeAndSaveShortCutListener',
      'editMode',
      'deactivateEditMode',
      'saveLocationFeatureSettingsChanges',
    );
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.featureLicense, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchOrganizationNames();
          this.fetchFeatureNames();
          assignSeveralProperties([this.$route.params, this.$route.query], ['organization', 'clinic', 'location'], this.preSelection, this.$store, 'featureLicenseSettings');
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.boundEditModeAndSaveShortCutListener);
  },
  methods: {
    async saveLocationFeatureSettingsChanges() {
      for (let i = 0; i < this.locationFeatureSettings.length; i++) {
        await LocationFeatureSettingsService.saveLocationFeatureSetting(
          this.selectedLocation,
          this.locationFeatureSettings[i].FeatureID,
          this.locationFeatureSettings[i].clinicWideUserLimit,
          this.locationFeatureSettings[i].locationWideUserLimit,
          this.locationFeatureSettings[i].activationDate,
          this.locationFeatureSettings[i].deactivationDate,
          this.referenceDataOverlayContent,
        );
      }
      this.editMode = false;
    },
    activateEditMode() {
      this.editMode = true;
    },
    async deactivateEditMode() {
      this.editMode = false;
      await this.fetchLocationFeatureSettings();
    },
    switchEditMode(value) {
      this.editMode = value;
    },
    fetchOrganizationNames() {
      if (this.organizationNames.length === 0) {
        OrganizationsService.getOrganizationNames()
          .then((organizationResponse) => {
            organizationResponse.data.forEach((orga) => {
              this.organizationNames.push({
                value: orga.OrganizationID,
                text: orga.name,
              });
            });
            if (this.preSelection.organization) {
              this.selectedOrganization = this.preSelection.organization;
              this.preSelection.organization = null;
            } else {
              this.selectedOrganization = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchClinicNames() {
      this.clinicNames = [];
      if (this.selectedOrganization) {
        ClinicsService.getClinicNames(this.selectedOrganization)
          .then((clinicResponse) => {
            clinicResponse.data.forEach((clinic) => {
              this.clinicNames.push({
                value: clinic.ClinicID,
                text: clinic.name,
              });
            });
            if (this.preSelection.clinic) {
              this.selectedClinic = this.preSelection.clinic;
              this.preSelection.clinic = null;
            } else {
              this.selectedClinic = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchLocationNames() {
      this.locationNames = [];
      if (this.selectedClinic) {
        LocationsService.getLocationNames(this.selectedClinic)
          .then((locationResponse) => {
            locationResponse.data.forEach((location) => {
              this.locationNames.push({
                value: location.LocationID,
                text: location.name,
              });
            });

            if (this.preSelection.location) {
              this.selectedLocation = this.preSelection.location;
              this.preSelection.location = null;
            } else {
              this.selectedLocation = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchFeatureNames() {
      this.featureNames = [];
      FeatureService.getFeatureNames()
        .then((featureResponse) => {
          featureResponse.data.forEach((feature) => {
            this.featureNames.push({
              value: feature.FeatureID,
              text: feature.name,
              description: `${feature.keyfield} | ${feature.description}`,
            });
          });
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
    fetchLocationFeatureSettings() {
      this.locationFeatureSettingsViewLoading = true;
      LocationFeatureSettingsService.getLocationFeatureSettings(this.selectedLocation).then((response) => {
        this.locationFeatureSettings = response.data;
        this.locationFeatureSettingsViewLoading = false;
      });
    },
    fetchFeature() {
      if (this.selectedFeature) {
        this.featureDataLoading = true;
        FeatureService.getFeature(this.selectedFeature)
          .then((response) => {
            this.featureData.featureID = response.data.FeatureID;
            this.featureData.keyfield = response.data.keyfield;
            this.featureData.description = response.data.description;
            if (!this.featureData.newReferenceData) {
              this.featureData.newReferenceData = response.data.featureReferenceData
                ? this.formatReferenceData(response.data.featureReferenceData)
                : null;
            }
            this.featureDataLoading = false;
          })
          .catch((err) => {
            this.featureDataLoading = false;
            this.$toast.error(err);
          });
      }
    },
    formatReferenceData(referenceData) {
      if (referenceData) {
        try {
          return JSON.parse(referenceData) ? JSON.stringify(JSON.parse(referenceData), null, 4) : null;
        } catch {
          this.$toast.error('Cant format this reference Data. No valid JSON');
          return null;
        }
      } else {
        return null;
      }
    },
    getLastReportedFeatures() {
      LocationFeaturesService.getMostRecentLocationFeatures(this.selectedLocation)
        .then((locationFeatures) => {
          for (let i = 0; i < locationFeatures.data.mostRecentLocationFeatures.length; i++) {
            // only add locationFeatures which aren't already in the array
            if (this.locationFeatureSettings.findIndex((locationFeature) => locationFeature.FeatureID === locationFeatures.data.mostRecentLocationFeatures[i].FeatureID) === -1) {
              this.locationFeatureSettings.push(locationFeatures.data.mostRecentLocationFeatures[i]);
            }
          }
        })
        .catch((err) => {
          this.$toast.error(err);
        })
    },
    addReportedFeatures() {
      LocationFeatureSettingsService.addReportedLocationFeatureSettings(this.selectedLocation)
        .then(() => {
          this.$toast.success('Location feature settings successfully updated!');
          this.fetchLocationFeatureSettings();
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
    confirmDelete(featureSetting = null) {
      if (!this.showConfirmDelete) {
        this.showConfirmDelete = true;
        this.deleteFeatureSettingID = featureSetting.FeatureID;
      } else {
        this.showConfirmDelete = false;
        this.deleteFeatureSettingID = null;
      }
    },
    deleteLocationFeatureSetting() {
      /*
      this.locationFeatureSettings = this.locationFeatureSettings.filter((locationFeature) => locationFeature.FeatureID !== this.deleteFeatureSettingID);
      this.confirmDelete();

       */
      LocationFeatureSettingsService.deleteLocationFeatureSettings(this.selectedLocation, this.deleteFeatureSettingID)
        .then(() => {
          this.$toast.success('Location feature setting successfully deleted');
          this.confirmDelete();
          this.fetchLocationFeatureSettings();
        })
        .catch((err) => {
          this.$toast.error(err);
          this.confirmDelete();
          this.fetchLocationFeatureSettings();
        });

    },
    saveFeatureSetting() {
      let validJSON = true;
      if (this.featureData.newReferenceData) {
        try {
          JSON.parse(this.featureData.newReferenceData);
        } catch (err) {
          this.$toast.error(`Can't save feature Setting. Reference data is not a valid JSON: ${err}`);
          validJSON = false;
        }
      }
      if (validJSON) {
        LocationFeatureSettingsService.saveLocationFeatureSetting(
          this.selectedLocation,
          this.selectedFeature,
          this.featureData.clinicWideUserLimit,
          this.featureData.locationWideUserLimit,
          this.featureData.activationDate,
          this.featureData.deactivationDate,
          this.featureData.newReferenceData,
          this.addToAllLocations,
        )
          .then(() => {
            this.$toast.success('Location feature settings successfully updated!');
            this.resetFeatureData();
            this.fetchLocationFeatureSettings();
          })
          .catch((err) => {
            this.$toast.error(err);
            this.resetFeatureData();
            this.fetchLocationFeatureSettings();
          });
      }
    },
    resetFeatureData() {
      this.showAddFeatureLicenseDialog = false;
      this.selectedFeature = null;
      this.featureData.featureID = null;
      this.featureData.keyfield = null;
      this.featureData.description = null;
      this.featureData.clinicWideUserLimit = null;
      this.featureData.locationWideUserLimit = null;
      this.featureData.activationDate = null;
      this.featureData.deactivationDate = null;
      this.featureData.newReferenceData = null;
      this.addToAllLocations = false;
    },
    editLocationFeatureSetting(featureSetting) {
      this.selectedFeature = featureSetting.FeatureID;
      this.featureData.clinicWideUserLimit = featureSetting.clinicWideUserLimit;
      this.featureData.locationWideUserLimit = featureSetting.locationWideUserLimit;
      this.featureData.activationDate = featureSetting.activationDate;
      this.featureData.deactivationDate = featureSetting.deactivationDate;
      this.featureData.newReferenceData = this.formatReferenceData(featureSetting.featureReferenceData);
      this.showAddFeatureLicenseDialog = true;
    },
    insertTab() {
      const textareas = document.getElementsByTagName('textarea');
      const count = textareas.length;
      for (let i = 0; i < count; i++) {
        textareas[i].onkeydown = function insertTabAtCursor(event) {
          if (event.keyCode === 9 || event.which === 9) {
            event.preventDefault();
            const s = this.selectionStart;
            this.value = `${this.value.substring(0, this.selectionStart)}\t${this.value.substring(this.selectionEnd)}`;
            this.selectionEnd = s + 1;
          }
        };
      }
    },
    openReferenceDataOverlay(referenceData) {
      this.showReferenceDataOverlay = true;
      this.referenceDataOverlayContent = referenceData;
    },
  },
};
</script>

<style></style>
